import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Arrow from '../icons/Arrow';
import { semiBoldFontFamily, mobileSize } from '../base_styles';

const CaseStudy = styled.div`
  clear: both;
  margin-bottom: 48px;
  position: relative;
`;

const CaseStudyLink = styled(Link)`
  text-decoration: none;
`;

const CaseStudyDescriptionWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-right: 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

   @media (max-width: ${mobileSize}) {
    max-width: 100%;
    padding-right: 0;
    position: static;
  }

`;

const CaseStudyDescription = styled.div`
  background: white;
  box-shadow: 32px 32px 0px ${(props) => props.boxShadowColor};
  max-width: 446px;
  padding: 57px 52px;

  p {
    margin: 24px 0
  }

  @media (max-width: ${mobileSize}) {
    box-shadow: 16px 16px 0px ${(props) => props.boxShadowColor};
    float: none;
    max-width: 100%;
    padding: 18px 18px 18px 0;
    position: static;

    p {
      margin: 10px 0 13px 0
    }
  }
`;

const CaseStudyImg = styled.div`
  max-width: 837px;
`;

const CaseStudyLinkText = styled.p`
  color: #2ADCC7;
  font-family: ${semiBoldFontFamily};
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;

  svg {
    margin-left: 8px;
  }
`;

const FeaturedCaseStudy = ({ caseStudy, backgroundColor }) => (
  <CaseStudy>
    <CaseStudyLink to={caseStudy.frontmatter.path}>
      <CaseStudyImg>
        <Img
          fluid={caseStudy.frontmatter.featuredImage.childImageSharp.fluid}
          backgroundColor={backgroundColor}
        />
      </CaseStudyImg>
      <CaseStudyDescriptionWrapper>
        <CaseStudyDescription boxShadowColor={backgroundColor}>
          <h3>{caseStudy.frontmatter.subtitle}</h3>
          <p>{caseStudy.frontmatter.shortDesc}</p>
          <CaseStudyLinkText>
            Read case study
            <Arrow />
          </CaseStudyLinkText>
        </CaseStudyDescription>
      </CaseStudyDescriptionWrapper>
    </CaseStudyLink>
  </CaseStudy>
);

FeaturedCaseStudy.propTypes = {
  caseStudy: PropTypes.shape({
    frontmatter: PropTypes.shape({
      subtitle: PropTypes.string,
      shortDesc: PropTypes.string,
      path: PropTypes.string,
      /* eslint-disable-next-line react/forbid-prop-types */
      featuredImage: PropTypes.object,
    }),
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

// fragment for getting data for featured case studies
export const query = graphql`
  fragment caseStudyFragment on MarkdownRemarkConnection {
    edges {
      node {
        id
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
          subtitle
          shortDesc
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 837) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default FeaturedCaseStudy;
