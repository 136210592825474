import PropTypes from 'prop-types';
import React from 'react';

const Arrow = ({ className }) => (
  <span className={className}>
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 7L16.2857 7" stroke="#0AC4AC" strokeWidth="2" />
      <path d="M11.1426 13L17.1426 7L11.1426 1" stroke="#0AC4AC" strokeWidth="2" />
    </svg>
  </span>
);

Arrow.propTypes = {
  className: PropTypes.string,
};

Arrow.defaultProps = {
  className: undefined,
};

export default Arrow;
