import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Arrow from '../icons/Arrow';
import { semiBoldFontFamily, mobileSize } from '../base_styles';

const CaseStudyLink = styled(Link)`
  text-decoration: none;
`;

const CaseStudyTitle = styled.p`
  color: #4F4D56;
  display: block;
  font-family: ${semiBoldFontFamily};
  font-size: 22px;
  line-height: 28px;
  padding: 24px 0 0 0;

  svg {
    margin-left: 8px;
  }

  @media (max-width: ${mobileSize}) {
    padding-top: 16px;
  }
`;

const NonFeaturedCaseStudy = ({ caseStudy }) => (
  <div>
    <CaseStudyLink to={caseStudy.frontmatter.path}>
      <Img
        fluid={caseStudy.frontmatter.featuredImage.childImageSharp.fluid}
        alt={caseStudy.frontmatter.subtitle}
        style={{ maxWidth: '580px' }}
      />
      <CaseStudyTitle>
        {caseStudy.frontmatter.subtitle}
        <Arrow />
      </CaseStudyTitle>
    </CaseStudyLink>
  </div>
);

NonFeaturedCaseStudy.propTypes = {
  caseStudy: PropTypes.shape({
    frontmatter: PropTypes.shape({
      subtitle: PropTypes.string,
      shortDesc: PropTypes.string,
      path: PropTypes.string,
      /* eslint-disable-next-line react/forbid-prop-types */
      featuredImage: PropTypes.object,
    }),
  }).isRequired,
};

export default NonFeaturedCaseStudy;
