import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { contentHorizontalPadding, fullWidth, mobileSize } from '../components/base_styles';
import FeaturedCaseStudy from '../components/case-studies/FeaturedCaseStudy';
import NonFeaturedCaseStudy from '../components/case-studies/NonFeaturedCaseStudy';
import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';

const Intro = styled.div`
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 0 ${contentHorizontalPadding};
`;

const IntroHeading = styled.h1`
  margin-top: 72px;

  @media (max-width: ${mobileSize}) {
    font-size: 32px;
    line-height: 40px;
    margin-top: 40px;
  }
`;

const FeaturedCaseStudyWrapper = styled.div`
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 80px ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    padding: 48px ${contentHorizontalPadding} 16px ${contentHorizontalPadding};
  }
`;

const CaseStudyWrapper = styled.div`
  border-top: 1px solid #9F9F9F;
  clear: both;
  display: grid;
  grid-gap: 58px 39px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 80px ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    grid-gap: 32px 39px;
    grid-template-columns: 100%;
    padding: 48px ${contentHorizontalPadding};
  }
`;

const shadowColors = ['#FCE8E4', '#C5F9E9', '#FCEECA'];

const WorkPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const featuredCaseStudies = edges
    .slice(0, 3)
    .map((edge, index) => (
      <FeaturedCaseStudy
        key={edge.node.id}
        caseStudy={edge.node}
        backgroundColor={shadowColors[index]}
      />
    ));

  const nonFeaturedCaseStudies = edges
    .slice(3)
    .map((edge) => (
      <NonFeaturedCaseStudy
        key={edge.node.id}
        caseStudy={edge.node}
      />
    ));

  return (
    <Layout>
      <SEO title="Work" />
      <Intro>
        <IntroHeading>Projects I’m most proud of...</IntroHeading>
      </Intro>
      <FeaturedCaseStudyWrapper>
        { featuredCaseStudies }
      </FeaturedCaseStudyWrapper>

      <CaseStudyWrapper>
        { nonFeaturedCaseStudies }
      </CaseStudyWrapper>
    </Layout>
  );
};

export default WorkPage;

WorkPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      /* eslint-disable-next-line react/forbid-prop-types */
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      ...caseStudyFragment
    }
  }
`;
